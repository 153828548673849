<template>
  <v-dialog v-model="dialog" max-width="700" scrollable>
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            {{`${edit?'Update':"Add"} category`}}
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="resetForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                  <v-text-field outlined dense label="Title" v-model="category.title"></v-text-field>
                  <span class="text-danger" v-if="$v.category.title.$error">This information is required</span>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                Status
                <v-switch v-model="category.is_active" name="check-button" :label="category.is_active ? 'Active' : 'Inactive'"></v-switch>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

            <v-btn
                class="btn btn-standard cancel-btn"
                depressed
                @click="resetForm"
            >Cancel
            </v-btn>

          <v-btn v-if="checkIsAccessible('question-bank-category', 'create')"
                 class="text-primary btn btn-primary btn px-9 py-4 my-3 font-size-3 mx-4"
                 depressed
                 @click="createOrUpdate"
                 :loading="isBusy"
          >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import {required} from "vuelidate/lib/validators";

import QuestionBankCategoryService from "@/core/services/quiz/question-bank/category/QuestionBankCategoryService";

const categoryService = new QuestionBankCategoryService();

export default {
  props: ['dialog'],
  validations: {
    category: {
      title: {required},
    }
  },
  data() {
    return {
      edit: false,
      isBusy: false,
      category: {
        title: null,
        is_active: true,
      }
    };
  },
  mounted() {
  },
  methods: {
    showModal(categoryId = null) {
      if (categoryId) {
        this.edit = true;

        this.getCategory(categoryId);
      }
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    getCategory(id) {
      categoryService.show(id).then(response => {
        this.category = response.data.category;
      });
    },
    createOrUpdate() {
      this.$v.category.$touch();
      if (this.$v.category.$error) {
        setTimeout(() => {
          this.$v.category.$reset();
        }, 3000);
      } else {
        if (this.category.id) {
          this.updateType();
        } else {
          this.createType();
        }
      }
    },
    createType() {
      this.isBusy = true;
      categoryService.store(this.category).then(response => {
        this.resetForm()
        this.isBusy = false;
      });
    },
    updateType() {
      this.isBusy = true;
      categoryService.update(this.category.id, this.category).then(response => {
        this.resetForm()
        this.isBusy = false;
      });
    },
    resetForm() {
      this.category = {
        title: null,
        is_active: true,
      };
      this.$v.category.$reset();
      this.$emit("refresh_list");

    },
    setType(category) {
      this.category = category;
      this.edit = true;
    },
    createCategory(){
      this.edit = false;
    }
  }
};
</script>