<template>
  <v-app>
     <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title pt-3 px-3 d-flex justify-content-between">
            <div class="breadcrumb-left">
              <h4> <router-link :to="{name:'question-bank'}">
                <i class="fas fa-arrow-left"></i>
              </router-link> &nbsp;&nbsp; Question bank category</h4>
              <div class="breadcrumb-sub-header">
                <router-link to="/dashboard">Dashboard</router-link>
                \   Question bank category
              </div>
            </div>
            <div class="breadcrumb-right">
              <div class="card-toolbar">
                <v-btn  @click="addCategory()"  class="mt-4 btn btn-primary" style="color: #fff">
                  <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                  Add category
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="">
            <v-skeleton-loader
                type="table-thead"
                v-if="loading"
            >
            </v-skeleton-loader>

            <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
            >
            </v-skeleton-loader>
            <table class="table">
              <thead>
                <tr class="px-3">
                  <th class="px-3 wrap-column">Title</th>
                  <th class="px-3 wrap-column">Status</th>
                  <th class="px-2">Action</th>
                </tr>
              </thead>
              <tbody v-if="categories.length > 0">
              <tr v-for="(category, index) of categories" :key="index">
                <td class="px-3 wrap-column">{{ category.title }}</td>
                <td class="px-3 wrap-column pt-3">
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': category.is_active, 'badge-danger': !category.is_active }"
                        >{{ category.is_active ? 'Active' : 'Inactive' }}</span>
                </td>
                <td class="px-1">
                  <b-dropdown
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                      right
                      no-flip
                  >
                    <template v-slot:button-content>
                      <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover ">


                      <b-dropdown-text tag="div" class="navi-item"
                                       v-if="checkIsAccessible('question-bank-category', 'edit')">
                        <a href="#" class="navi-link" @click="editCategory(category)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                          <span class="navi-text"> Edit </span>
                        </a>
                      </b-dropdown-text>

                      <b-dropdown-text tag="div" class="navi-item"
                                       v-if="checkIsAccessible('question-bank-category', 'delete')">
                        <a href="#" class="navi-link" @click="deleteCategory(category.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                          <span class="navi-text">  Delete</span>
                        </a>
                      </b-dropdown-text>

                    </div>
                    <!--end::Navigation-->
                  </b-dropdown>

                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="3" class="px-3 text-center">No data available to display.</td>
              </tr>
              </tbody>
            </table>
            <b-pagination
                v-if="categories.length > 0"
                class="pull-right mt-7"
                @input="getCategories"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                :disabled="loading"
            ></b-pagination>
          </div>

        </div>
      </div>
    </div>
    <create-and-update
        ref="question-bank-category"
        :dialog="dialog"
        @refresh_list="getCategories"
    ></create-and-update>
  </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import QuestionBankCategoryService from "@/core/services/quiz/question-bank/category/QuestionBankCategoryService";

const categoryService = new QuestionBankCategoryService();

export default {
  components: {
    CreateAndUpdate,
  },
  data() {
    return {
      categories: [],
      dialog: false,
      loading: false,
      category: {},
      total: null,
      perPage: null,
      page: null,
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      this.dialog = false;
      this.loading=true;
      categoryService
          .paginate().then((response) => {
              this.categories = response.data.data;
              this.page = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.loading = false;
          });
    },
    addCategory() {
      this.dialog = true;
      this.$refs['question-bank-category'].createCategory()
    },
    editCategory(category) {
      this.dialog = true
      this.$refs['question-bank-category'].setType(category)
    },
    deleteCategory(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            categoryService.delete(id).then((response) => {
              this.getCategories()
            });
          }
        }
      });
    },
  },
};
</script>
